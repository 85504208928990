import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import { authCheckState } from '../../redux/actions/auth'

export class ClientHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companies: null
        }
    }

    componentDidMount() {
        this.props.authCheckState(this.getProjects)
    }

    getProjects = () => {
        axios.get('/api/project', {
            headers: {
                Authorization: 'Bearer ' + this.props.access_token
            }
        }).then((res) => {
            this.setState({
                companies: res.data
            })
        })
    }

    render() {
        return (
            <div className="client_homepage page_top_margin">
                <div className="container">
                    <h1 className="page_title">PROJECTS</h1>
                    <div className="projects">
                        {this.state.companies !== null ?
                            this.state.companies.length > 0 ?
                                this.state.companies.map((item) =>
                                    <Link to={`project/${item.pk}/0/0/0`} key={item.pk}>
                                        <div key={item.pk} className="project">
                                            <h3 className="project_name">{item.title}</h3>
                                        </div>
                                    </Link>
                                )
                                : ''
                            : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        access_token: state.authReducer.access_token
    }
}

export default connect(mapStateToProps, { authCheckState })(ClientHome)
