import ReactDOM from 'react-dom'
import App from './App'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from "react-redux";
import store from "./redux/store";
import './styles/main.scss'

import axios from 'axios'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'))
