import React, { Component } from 'react'
import Nav from "components/Nav";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { authCheckState } from "../redux/actions/auth";
import infographic from '../images/infographic.jpg'

export class Instructions extends Component {
    render() {
        return (
            <>
                <Nav/>
                <div className="instruction_page">
                    <div className="container">
                        <img className='infographic' src={infographic} alt=""/>
                    </div>
                </div>
                
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        access_token: state.authReducer.access_token,
        active_user_pk: state.authReducer.user_pk,
        active_user_admin: state.authReducer.admin,
    };
};

export default compose(withRouter, connect(mapStateToProps, { authCheckState }))(Instructions);
