import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Home from "./components/Home/Home";
import Login from "./components/Login";
import Project from "components/Project";
import { compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { authCheckState } from "./redux/actions/auth";
import Instructions from "components/Instructions";

export class App extends Component {
  componentDidMount() {
    this.props.authCheckState();
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" >
            {this.props.authenticated ? <Home /> : <Login />}
          </Route >
          <Route path="/instructions" >
            {this.props.authenticated ? <Instructions /> : <Login />}
          </Route>
          <Route path="/project/:p_id/:v_id/:d_id/:v_t" >
            {this.props.authenticated ? <Project /> : <Login />}
          </Route>
          <Route path="/login">
            {this.props.authenticated ? <Redirect to="/" /> : <Login />}
          </Route>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authReducer.authenticated,
  };
};

export default compose(withRouter, connect(mapStateToProps, { authCheckState }))(App);
