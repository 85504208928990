import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from 'react-router-dom';
import axios from "axios";
import { withRouter } from "react-router";
import Nav from "components/Nav";
import { authCheckState } from "../redux/actions/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faThumbsUp, faCaretUp, faPlus, faTimes, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import WebSocketInstance from '../websocket'

export class Project extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project: null,
            current_version: null,
            current_discussion: null,
            add_discussion_dialog: null,
            add_version_dialog: false,
            selectedFile: null,
            file_upload_status: 0,
            p_id: parseInt(this.props.match.params.p_id),
            d_id: parseInt(this.props.match.params.d_id),
            v_id: parseInt(this.props.match.params.v_id),
            v_t: parseInt(this.props.match.params.v_t),
        };
        this.chat_box = React.createRef();
        this.video = React.createRef();
        this.chat_input = React.createRef();
        this.description = React.createRef();

        this.props.authCheckState(this.getProject());

        WebSocketInstance.disconnect()


        if (this.state.d_id === 0 && this.state.p_id !== null && this.state.p_id !== undefined) {
            WebSocketInstance.connect('project_' + this.state.p_id)
            this.waitForSocketConnection(() => {
                WebSocketInstance.addCallbacks(
                    this.setDiscussion.bind(this),
                    this.addComment.bind(this));
                WebSocketInstance.fetchMessages('project_chat', this.props.active_user_pk, this.state.p_id)
            })
        } else {
            WebSocketInstance.connect('discussion_' + this.state.d_id + '_' + this.state.v_id)
            this.waitForSocketConnection(() => {
                WebSocketInstance.addCallbacks(
                    this.setDiscussion.bind(this),
                    this.addComment.bind(this));
                WebSocketInstance.fetchMessages('discussion_chat', this.props.active_user_pk, this.state.d_id)
            })
        }
        this.profile_background_color = [
            "#483D8B", "#228B22", "#0000CD", "#000080", "#808000", "#800080", "#8B4513", "#2E8B57", "#FF6347", "#008080", "#20B2AA",
            '#D2691E', '#B22222', '#9370DB', '#191970', '#DB7093', "#483D8B", "#228B22", "#0000CD", "#000080", "#808000", "#800080", "#8B4513",
            "#2E8B57", "#FF6347", "#008080", "#20B2AA", '#D2691E', '#B22222', '#9370DB', '#191970', '#DB7093',
        ]
    }


    componentDidMount() {
        this.waitForVideoToLoaded(() => {
            if (this.state.v_t !== 0) {
                this.video.current.currentTime = this.state.v_t
            }
        })
    }

    waitForVideoToLoaded(callback) {
        const component = this;
        setTimeout(function () {
            if (this.video !== null && this.video !== undefined) {
                callback();
                return;
            } else {
                component.waitForSocketConnection(callback);
            }
        }, 100);
    }


    waitForSocketConnection(callback) {
        const component = this;
        setTimeout(function () {
            if (WebSocketInstance.state() === 1 && component.props.active_user_pk !== null) {
                callback();
                return;
            } else {
                component.waitForSocketConnection(callback);
            }
        }, 100);
    }

    chatScrollToBottom = () => {
        if (this.chat_box.current !== null && this.chat_box.current !== undefined) {
            this.chat_box.current.scrollTop = this.chat_box.current.scrollHeight
        }
    }

    componentWillUnmount() {
        WebSocketInstance.disconnect()
    }

    addComment(comment) {
        this.setState({
            current_discussion: {
                ...this.state.current_discussion,
                comments: [...this.state.current_discussion.comments, comment]
            },
        })
        this.chatScrollToBottom()
    }

    sendMessage(type, chat_room_pk) {
        if (!this.state.current_discussion.solved) {
            let message = this.chat_input.current.value
            console.log(message.length)
            if (message.length < 500) {
                if (message.trim() !== '') {
                    WebSocketInstance.sendMessage({
                        command: "new_message",
                        type: type,
                        message: message,
                        from: this.props.active_user_pk,
                        chat_room_pk: chat_room_pk,
                    })
                    this.chat_input.current.value = ''
                }
            }else {
                alert('message only me can be 500 characters long')
            }
        } else {
            alert('This discussion has been resolved, you must reopen this discussion before you can send a message')
        }
    }

    setDiscussion(data) {
        this.setState({
            current_discussion: {
                type: data.type,
                chat_room_pk: data.chat_room_pk,
                title: data.title,
                solved: data.solved,
                comments: data.comments.reverse(),
                description: data.description,
                video_time: data.video_time
            },
        });
        this.chatScrollToBottom()
    }

    renderMessages = (messages) => {
        return messages.map(item =>
            <div key={item.pk}
                className={item.author_pk !== this.props.active_user_pk ? "message other" : "message"}>
                <div
                    className="profile_photo"
                    title={item.author}
                    style={item.author_pk !== this.props.active_user_pk ?
                        {
                            right: '-10px',
                            background: this.profile_background_color[item.author[0].toUpperCase().charCodeAt(0) - 65],
                        } : {
                            left: '-10px',
                            background: this.profile_background_color[item.author[0].toUpperCase().charCodeAt(0) - 65],
                        }}
                >{item.author[0].toUpperCase()}</div>
                <div className="timestamp">
                    <span className="author">{item.author}</span>
                    <span>{item.placed_on}</span>
                </div>
                <p>{item.content}</p>
            </div>)

    }

    getProject = () => {
        axios
            .get(`/api/project/${this.state.p_id}`, {
                headers: {
                    Authorization: "Bearer " + this.props.access_token,
                },
            })
            .then((res) => {
                let current_version = null
                if (this.state.v_id === 0) {
                    current_version = res.data.versions[res.data.versions.length - 1]
                } else {
                    res.data.versions.map(item => parseInt(item.pk) === this.state.v_id ? current_version = item : null)
                }


                if (res.data.pk) {
                    this.setState({
                        project: res.data,
                        current_version: current_version,
                    });

                }
            });
    };

    getVersion = (id) => {
        axios
            .get(`/api/version/${id}`, {
                headers: {
                    Authorization: "Bearer " + this.props.access_token,
                },
            })
            .then((res) => {
                if (res.data.pk) {
                    this.setState({
                        current_version: res.data,
                    });
                    let myElements = document.getElementsByClassName("version");
                    for (let i = 0; i < myElements.length; i++) {
                        myElements[i].classList.remove('active')
                    }
                    document.querySelector('.version_' + res.data.pk).classList.add('active')
                }
            });
    };



    openChatbox = (e, type) => {
        let myElements = document.getElementsByClassName("chat_box_button");
        for (let i = 0; i < myElements.length; i++) {
            myElements[i].classList.remove('active')
        }
        e.target.classList.toggle('active')
        WebSocketInstance.disconnect()
        if (type === 'discussion') {
            WebSocketInstance.connect('discussion_' + this.props.match.params.d_id + '_' + this.props.match.params.v_id)
            this.waitForSocketConnection(() => {
                WebSocketInstance.addCallbacks(
                    this.setDiscussion.bind(this),
                    this.addComment.bind(this));
                WebSocketInstance.fetchMessages('discussion_chat', this.props.active_user_pk, this.props.match.params.d_id)
                this.video.current.currentTime = this.props.match.params.v_t
            })
        } else if (type === 'project') {
            WebSocketInstance.connect('project_' + this.state.p_id)
            this.waitForSocketConnection(() => {
                WebSocketInstance.addCallbacks(
                    this.setDiscussion.bind(this),
                    this.addComment.bind(this));
                WebSocketInstance.fetchMessages('project_chat', this.props.active_user_pk, this.props.match.params.p_id)
            })
        }
    }

    createDiscussion = () => {
        let title = document.querySelector('#discussion_title_input').value
        let description = document.querySelector('#discussion_description_input').value
        let current_video_timestamp = this.video.current.currentTime
        if (title !== null && title !== "" && title !== undefined && this.state.current_version !== null) {
            axios.post("/api/discussion", {
                type: 'create',
                title,
                description,
                current_video_timestamp,
                version_pk: this.state.current_version.pk
            },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.props.access_token
                    }
                })
                .then((res) => {
                    this.setState({
                        current_version: res.data['version'],
                        add_discussion_dialog: null
                    })
                    WebSocketInstance.disconnect()
                    WebSocketInstance.connect('discussion_' + res.data['discussion'].pk + '_' + this.state.current_version.pk)
                    this.waitForSocketConnection(() => {
                        WebSocketInstance.fetchMessages('discussion_chat', this.props.active_user_pk, res.data['discussion'].pk)
                    })
                })
        }
    }

    millisecondsToSeconds = (ms) => {
        if (ms !== null && ms !== undefined) {
            let m = Math.floor(ms / 60);
            let s = Math.round(ms % 60);
            if (m.toString().length < 2) { m = '0' + m; }
            if (s.toString().length < 2) { s = '0' + s; }
            return m + ':' + s
        }
        else {
            return null
        }

    }

    openAddDiscussionDialog = () => {
        let timenow = this.video.current.currentTime
        let timestamp = this.millisecondsToSeconds(timenow)
        if (timestamp !== '00:00') {
            this.setState({
                add_discussion_dialog:
                    <div className="add_discussion_dialog_box">
                        <div className="content">
                            <div className="header">
                                <div className="title">Create a new discussion</div>
                                <div className="close">
                                    <FontAwesomeIcon icon={faTimes} onClick={() => { this.setState({ add_discussion_dialog: null }) }} />
                                </div>
                            </div>
                            <div className="body">
                                <div className="current_video_timestamp">
                                    Video timestamp: {timestamp}
                                </div>
                                <div className="discussion_title">
                                    <label htmlFor="discussion_title">Topic</label>
                                    <input type="text" name="discussion_title" id="discussion_title_input" />
                                </div>
                                <div className="discussion_description">
                                    <label htmlFor="discussion_description">Description</label>
                                    <textarea type="text" name="discussion_description" id="discussion_description_input" />
                                </div>
                                <button className="button create" onClick={() => this.props.authCheckState(this.createDiscussion())}>Create</button>
                            </div>
                        </div>
                    </div>
            })
        } else {
            alert("you cannot open a discussion on 0th frame of the video")
        }
    }

    uploadVideo = (e) => {
        if (this.state.selectedFile !== null) {
            if (this.state.selectedFile.type === "video/mp4") {
                let version_number = document.querySelector('#version_number').value
                if (version_number === undefined || version_number === "") {
                    alert('Version number is required field')
                } else {
                    let reg = /^[+-]?\d+(\.\d+)?$/
                    if (reg.test(version_number)) {
                        if (this.state.file_upload_status === 0) {
                            console.log(this.state.selectedFile)
                            const fd = new FormData();
                            fd.append('video', this.state.selectedFile, this.state.selectedFile.name)
                            fd.append('project_pk', this.state.project.pk)
                            fd.append('version_number', version_number)
                            let config = {
                                headers: {
                                    Authorization: "Bearer " + this.props.access_token,
                                },
                                onUploadProgress: function (progress) {
                                    let percentCompleted = Math.round(progress.loaded / progress.total * 100) + ' %'
                                    this.setState({
                                        file_upload_status: percentCompleted
                                    })
                                    console.log(this.state.file_upload_status)
                                }.bind(this),
                            }
                            axios.post(`/api/version`,
                                fd,
                                config)
                                .then((res) => {
                                    if (res.data) {
                                        this.setState({
                                            file_upload_status: 0,
                                            add_version_dialog: false
                                        })
                                        window.location.reload();
                                    }
                                });
                        }
                    } else {
                        alert('Version number must be a number')
                    }
                }
            } else {
                alert('You can only upload mp4 file for now')
            }
        } else {
            alert("You have not selected a file yet")
        }
    }

    fileSelectedHandler = e => {
        this.setState({
            selectedFile: e.target.files[0]
        })
    }

    updateDiscussion = (action, description_pk) => {
        if (window.confirm(`Are you want to ${action} this discussion?`)) {
            if (action !== null && action !== undefined) {
                axios.post("/api/discussion", {
                    type: 'update',
                    description_pk,
                    action,
                    version_pk: this.state.current_version.pk
                },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.props.access_token
                        }
                    })
                    .then((res) => {
                        this.setState({
                            current_discussion: { ...this.state.current_discussion, solved: res.data.solved },
                            current_version: { ...this.state.current_version, discussions: res.data.discussions }
                        })
                    })
            }
        } else { }
    }

    isFloat = (n) => {
        return Number(n) === n && n % 1 !== 0;
    }

    render() {
        return (
            <>
                <Nav />
                {this.state.project !== null && this.state.project.project_type === 'visualleaflet' ? (
                    <div className="project_page page_top_margin">
                        <div className="container">
                            <h1 className="page_title">{this.state.project.title}</h1>
                            <div className="members">
                                {this.state.project.members.length > 0 ?
                                    this.state.project.members.map((item) =>
                                        <div
                                            className="member"
                                            title={item.username}
                                            key={item.id}
                                            style={
                                                {
                                                    left: '-10px',
                                                    background: this.profile_background_color[item.username[0].toUpperCase().charCodeAt(0) - 65],
                                                }}>
                                            {item.username[0].toUpperCase()}
                                        </div>) : ''}
                            </div>
                            <div className="versions">
                                {this.state.project.versions.length > 0
                                    ? this.state.project.versions.map((item) => (
                                        <Link to={`/project/${this.state.p_id}/${item.pk}/0/0`}
                                            onClick={() => { this.props.authCheckState(this.getVersion(item.pk)); }}>
                                            <div key={item.pk}
                                                className={
                                                    this.state.v_id !== 0 ?
                                                        this.state.v_id === parseInt(item.pk) ?
                                                            this.isFloat(item.number) ? "version active float version_" + item.pk : "version active version_" + item.pk
                                                            : this.isFloat(item.number) ? "version float version_" + item.pk : "version version_" + item.pk :
                                                        this.state.project.versions[this.state.project.versions.length - 1].pk === item.pk ? this.isFloat(item.number) ? "version active float version_" + item.pk : "version active version_" + item.pk
                                                            : this.isFloat(item.number) ? "version float version_" + item.pk : "version version_" + item.pk}
                                            >
                                                {item.number}
                                            </div>
                                        </Link>
                                    )) : ""}
                                {this.props.active_user_admin === true ? <div className="version" onClick={() => this.setState({ add_version_dialog: true })}>+</div> : ''}
                            </div>
                            {this.state.current_version !== null ? (
                                <div className="project">
                                    <div className="file">
                                        <video ref={this.video} controls src={"" + this.state.current_version.path}></video>
                                        <Link className={this.state.current_discussion ?
                                            this.state.current_discussion.title === this.state.project.title ?
                                                "button chat_box_button active" :
                                                "button chat_box_button" :
                                            'button chat_box_button'}
                                            to={`/project/${this.props.match.params.p_id}/0/0/0`}
                                            onClick={(e) => this.props.authCheckState(this.openChatbox(e, 'project'))}><FontAwesomeIcon className={'project_chatroom'} icon={faComments} />Project chatroom
                                            </Link>
                                        <div className="discussion_list_header">
                                            <h4 className='discussion_threads'>DISCUSSION THREADS</h4>
                                            <button onClick={() => this.props.authCheckState(this.openAddDiscussionDialog())} className="button create_discussion"><FontAwesomeIcon icon={faPlus} /></button>
                                        </div>

                                        {this.state.current_version.discussions
                                            ? this.state.current_version.discussions.length > 0
                                                ? this.state.current_version.discussions.map((item) =>
                                                    <Link
                                                        to={`/project/${this.state.p_id}/${this.props.match.params.v_id}/${item.pk}/${item.video_time}`}
                                                        key={item.pk}
                                                        className={
                                                            this.state.current_discussion ?
                                                                this.state.current_discussion.title === item.title ?
                                                                    "button chat_box_button active" :
                                                                    "button chat_box_button" : ''
                                                        }
                                                        onClick={(e) => this.props.authCheckState(this.openChatbox(e, 'discussion'))}>
                                                        <FontAwesomeIcon className={item.solved ? 'triangle solved' : 'triangle'} icon={faCaretUp} />
                                                        <span className={"video_timestamp"}>{this.millisecondsToSeconds(item.video_time)}</span>
                                                        <span className={'initiator'}>{item.initiator.username} - </span>
                                                        <span className={"discussion_title"}> {item.title}</span>
                                                    </Link>)
                                                : ""
                                            : ""}
                                    </div>
                                    {this.state.current_discussion !== null ? (
                                        <div className="chat_container">
                                            <div className="chat_display_box">
                                                <div className="chat_container_header">
                                                    <div className="status">
                                                        <FontAwesomeIcon
                                                            style={this.state.current_discussion.solved === undefined ? '' : this.state.current_discussion.solved ? { color: '#333', fontSize: '1.8rem' } : { color: "#4ba128", fontSize: '1.8rem' }}
                                                            icon={this.state.current_discussion.solved === undefined ? faComments : faCaretUp} />
                                                        <span className="video_time">{this.millisecondsToSeconds(this.state.current_discussion.video_time)}</span>
                                                    </div>
                                                    {this.state.current_discussion.solved === true ?
                                                        <button className="resolve button" onClick={() => this.updateDiscussion('reopen', this.state.current_discussion.chat_room_pk)}>REOPEN</button> :
                                                        this.state.current_discussion.solved === undefined ?
                                                            '' : <button className="resolve button" onClick={() => this.updateDiscussion('resolve', this.state.current_discussion.chat_room_pk)}>RESOLVE</button>}
                                                </div>
                                                <div className="discussion_topic_container">
                                                    <span className="discussion_topic">
                                                        Discussion topic:{" "}
                                                        <span className="topic">{this.state.current_discussion.title}</span>
                                                    </span>
                                                    <span className="description_toggle">  <FontAwesomeIcon icon={faCaretDown} onClick={()=> { this.description.current.classList.toggle('active')}} /></span>
                                                </div>
                                                <div className="description" ref={this.description}>
                                                    <p>{this.state.current_discussion.description}</p>
                                                </div>
                                                <div className="chat_box" ref={this.chat_box}>
                                                    {this.state.current_discussion.comments !== null &&
                                                        this.state.current_discussion.comments.length > 0
                                                        ? this.renderMessages(this.state.current_discussion.comments) : ""}
                                                </div>
                                            </div>
                                            <div className="chat_input_box">
                                                <textarea ref={this.chat_input} className="chat_input" type="text" id="chat_input" name="chat_input_box" placeholder="........" />
                                                <div className="chat_buttons">
                                                    <FontAwesomeIcon className="thumbs_up" icon={faThumbsUp} />
                                                    <button className="send button" onClick={() => this.sendMessage(this.state.current_discussion.type, this.state.current_discussion.chat_room_pk)}>SEND</button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ""}
                                </div>
                            ) : ""}
                        </div>
                        {this.state.add_version_dialog ?
                            <div className="add_version_dialog_box">
                                <div className="content">
                                    <div className="header">
                                        <div className="title">Upload a new version</div>
                                        <div className="close">
                                            <FontAwesomeIcon icon={faTimes} onClick={() => { this.setState({ add_version_dialog: false }) }} />
                                        </div>
                                    </div>
                                    <div className="body">
                                        <div className="video_upload">
                                            <div className="version_number">
                                                <label htmlFor="version_number">Version number</label>
                                                <input type="text" name="version_number" id="version_number" />
                                            </div>
                                            <input type="file" className="upload_input" onChange={this.fileSelectedHandler} />
                                            <span>Progress </span>{this.state.file_upload_status}
                                            <button className="button" onClick={this.uploadVideo}>Upload video</button>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''}
                        {this.state.add_discussion_dialog}
                    </div>
                ) : ""}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        access_token: state.authReducer.access_token,
        active_user_pk: state.authReducer.user_pk,
        active_user_admin: state.authReducer.admin,

    };
};

export default compose(withRouter, connect(mapStateToProps, { authCheckState }))(Project);
