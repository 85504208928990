import React, { Component } from "react";
import { connect } from "react-redux";
import ClientHome from "./ClientHome";
import AdminHome from "./AdminHome";
import Nav from "components/Nav";

export class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeUser: null,
            body: null
        }
    }
    render() {
        return (
            <>
                <Nav />
                {this.props.admin !== null ? this.props.admin === true ? <AdminHome /> : <ClientHome /> : ""}
            </>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        access: state.authReducer.access_token,
        admin: state.authReducer.admin,
        username: state.authReducer.username,
    }
}

export default connect(mapStateToProps)(Home);
