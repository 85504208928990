import * as actions from '../actionTypes'
const initialState = {
    authenticated: false,
    refresh_token: null,
    access_token: null,
    accessTokenExpirationDate: null,
    refreshTokenExpirationDate: null,
    username: null,
    user_pk:null,
    admin: null,
    companyId: null,
    companyName: null,
    active_notifications: null,
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case actions.LOGGED_IN:
            return {
                ...state,
                authenticated: true,
                access_token: action.payload.access,
                refresh_token: action.payload.refresh,
                accessTokenExpirationDate: action.payload.accessTokenExpirationDate,
                refreshTokenExpirationDate: action.payload.refreshTokenExpirationDate
            }
        case actions.LOGGED_OUT:
            return {
                ...state,
                authenticated: false,
                access_token: null,
                refresh_token: null,
                accessTokenExpirationDate: null,
                refreshTokenExpirationDate: null,
                username: null,
                user_pk:null,
                admin: null,
                companyId: null,
                companyName: null,
            }
        case actions.USERINFO_FETCHED:
            return {
                ...state,
                username: action.payload.username,
                user_pk: action.payload.user_pk,
                admin: action.payload.admin,
                companyId: action.payload.companyId,
                companyName: action.payload.companyName,
                active_notifications: action.payload.active_notifications
            }
        case actions.SET_NOTIFICATION:
            return {
                ...state,
                active_notifications: action.payload.active_notifications
            }
        case actions.ADD_NOTIFICATION:
            return {
                ...state,
                active_notifications: action.payload.updated_notification
            }
        default:
            return state
    }
}