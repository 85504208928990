import React, { Component } from 'react'
import { connect } from "react-redux";
import { authLogin ,userInfoFetch} from '../redux/actions/auth'
import axios from 'axios'

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usernameValue: '',
            passwordValue: '',
    };
        this.username = React.createRef();
        this.password = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // onhandleChange and handleSubmit are used to store the form state in the component state.
    // Documentation: https://reactjs.org/docs/forms.html
    handleChange(event) {
        if (event.target.id === "username") {
            this.setState({usernameValue: event.target.value});
        }
        else {
            this.setState({passwordValue: event.target.value});
        }
    }  

    handleSubmit(event) {
        this.Login();
        event.preventDefault();
    }

    Login () {
        let username = this.state.usernameValue
        let password = this.state.passwordValue
        if (username !== "" && password !== "") {
            axios
                .post("/api/token", {
                    username,
                    password,
                })
                .then((res) => {
                    if (res.status === 200) {

                        const access = res.data.access;
                        const refresh = res.data.refresh;
                        const accessTokenExpirationDate = new Date(new Date().getTime() + 540 * 1000);
                        const refreshTokenExpirationDate = new Date(new Date().getTime() + 1.642e+6 * 1000);

                        this.props.userInfoFetch(access)
                        
                        this.props.authLogin(access, refresh, accessTokenExpirationDate, refreshTokenExpirationDate)

                        localStorage.setItem("authenticated", true);
                        localStorage.setItem("access_token", access);
                        localStorage.setItem("refresh_token", refresh);
                        localStorage.setItem("accessTokenExpirationDate", accessTokenExpirationDate);
                        localStorage.setItem("refreshTokenExpirationDate", refreshTokenExpirationDate);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        document.getElementById("invalid-message").style.display = "block";
                        console.log(error.response.data);
                        console.log(error.response.status);
                    }
                });
        }
    }

    render() {
        return (
            <div className="login_page">
                <div className="container">
                    <div className="login_container">
                        <h1>Login</h1>
                        <form onSubmit={this.handleSubmit}>
                            <label htmlFor="username">Username</label>
                            <input ref={this.username} id="username" type="text" name="Username" value={this.state.usernameValue} placeholder="Enter username" onChange={this.handleChange} required />
                            <label htmlFor="username" name="Password"  >Password</label>
                            <input ref={this.password} id="password" type="password" value={this.state.passwordValue} placeholder="Enter password" onChange={this.handleChange} required />
                            <p id="invalid-message">Invalid username password combination</p>
                            <input type="submit" className="button" value="Login"/>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, { authLogin, userInfoFetch})(Login)
