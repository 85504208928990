
import * as actions from '../actionTypes'
import axios from 'axios'
import store from '../store';

export const authLogin = (access, refresh, accessTokenExpirationDate, refreshTokenExpirationDate) => (dispatch) => {
    dispatch({
        type: actions.LOGGED_IN,
        payload: {
            access,
            refresh,
            accessTokenExpirationDate,
            refreshTokenExpirationDate
        }
    })
};

export const authLogout = () => (dispatch) => {
    localStorage.setItem("authenticated", false);
    localStorage.setItem("access_token", null);
    localStorage.setItem("refresh_token", null);
    localStorage.setItem("accessTokenExpirationDate", null);
    localStorage.setItem("refreshTokenExpirationDate", null);
    dispatch({
        type: actions.LOGGED_OUT,
    })
};

export const userInfo = (username, admin, companyId, companyName,user_pk, active_notifications) => (dispatch) => {
    dispatch({
        type: actions.USERINFO_FETCHED,
        payload: {
            username,
            admin,
            companyId,
            companyName,
            user_pk,
            active_notifications
        }
    })
};

export const authCheckState = (apiCall=null) => (dispatch) => {
    let authenticated = localStorage.getItem("authenticated");
    if (authenticated === 'true') {
        var access_token = localStorage.getItem("access_token");
        var refresh_token = localStorage.getItem("refresh_token");
        var accessTokenExpirationDate = localStorage.getItem("accessTokenExpirationDate");
        var refreshTokenExpirationDate = localStorage.getItem("refreshTokenExpirationDate");


        if (new Date(refreshTokenExpirationDate) >= new Date()) {
            if (new Date(accessTokenExpirationDate) <= new Date()) {
                axios.post("/api/token/refresh", {
                    refresh: refresh_token
                })
                    .then((res) => {
                        if (res.status === 200) {
                            access_token = res.data.access;
                            accessTokenExpirationDate = new Date(new Date().getTime() + 540 * 1000);
                            localStorage.setItem("access_token", access_token);
                            localStorage.setItem("accessTokenExpirationDate", accessTokenExpirationDate);

                            console.log('auth check 1')
                            userInfoFetch(access_token)(dispatch)
                            authLogin(access_token, refresh_token, accessTokenExpirationDate, refreshTokenExpirationDate)(dispatch)
                            if (apiCall!==null){
                                apiCall()
                            }
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.log(error.response.status);
                            console.log(error.response.data);
                        }
                    });
            } else {
                const state = store.getState()
                if (state.authReducer.admin == null) {
                    userInfoFetch(access_token)(dispatch)
                }
                authLogin(access_token, refresh_token, accessTokenExpirationDate, refreshTokenExpirationDate)(dispatch)
                if (apiCall!==null){
                    apiCall()
                }
            }
        } else {
            localStorage.setItem("authenticated", false);
            localStorage.setItem("access_token", null);
            localStorage.setItem("refresh_token", null);
            localStorage.setItem("accessTokenExpirationDate", null);
            localStorage.setItem("refreshTokenExpirationDate", null);
        }
    }
}

export const userInfoFetch = (access_token) => (dispatch) => {
    var username = null;
    var user_pk = null;
    var admin = false;
    var companyId = null;
    var companyName = null;
    var active_notifications = null;
    
    axios.post("/api/user", {},
        {
            headers: {
                Authorization: 'Bearer ' + access_token
            }
        })
        .then((res) => {
            
            username = res.data.user.username
            user_pk = res.data.user.id
            if (res.data.user.active_notifications !== null) {
                active_notifications = res.data.user.active_notifications
            }
            
            res.data.user.is_staff === true ? admin = true : admin = false
            if (res.data.user.client_user !== null) {
                companyId = res.data.user.client_user.company.pk;
                companyName = res.data.user.client_user.company.name;
            }
            userInfo(username, admin, companyId, companyName, user_pk, active_notifications)(dispatch)
        })
}

export const setNotification = (active_notifications) => (dispatch) => {
    dispatch({
        type: actions.SET_NOTIFICATION,
        payload: {active_notifications}
    })
}

export const addNotification = (updated_notification) => (dispatch) => {
    dispatch({
        type: actions.ADD_NOTIFICATION,
        payload: {updated_notification}
    })
}