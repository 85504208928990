import { SOCKET_URL } from "./settings";

class WebSocketService {
  static instance = null;
  callbacks = {};

  static getInstance() {
    if (WebSocketService.instance===null) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  constructor() {
    this.socketRef = null;
  }

  connect(chatUrl) {
    if (chatUrl !== undefined && chatUrl!== null){
      const path = `${SOCKET_URL}/ws/chat/${chatUrl}/`;
      this.socketRef = new WebSocket(path);
      this.socketRef.onopen = () => {
      }; 
      this.socketRef.onmessage = e => {
        this.socketNewMessage(e.data);
      };
      this.socketRef.onerror = e => {
        console.log(e.message);
      };
      this.socketRef.onclose = () => {
        this.connect();
      };
    }
  }

  disconnect() {
    if (this.socketRef !== null) {
      this.socketRef.close();
    }
  }

  socketNewMessage(data) {
    const parsedData = JSON.parse(data);
    const command = parsedData.command;

    if (Object.keys(this.callbacks).length === 0) {
      return;
    }
    if (command === "notification") {
      this.callbacks[command](parsedData.message);
    }
  }

  addCallbacks( newNotificationCallback) {
    this.callbacks["notification"] = newNotificationCallback;
  }

  sendMessage(data) {
    try {
      this.socketRef.send(JSON.stringify({ ...data }));
    } catch (err) {
      console.log(err.message);
    }
  }

  state() {
    if (this.socketRef !== null){
      return this.socketRef.readyState;
    }else {
      return null
    }
    
  }
}

const NotificationWebSocketInstance = WebSocketService.getInstance();

export default NotificationWebSocketInstance;
