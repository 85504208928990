import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import {authCheckState} from '../../redux/actions/auth'
export class AdminHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companies: null
        }
    }

    componentDidMount() {
        this.props.authCheckState(this.getClients)
    }

    getClients = () => {
        axios.get('/api/companie', {
            headers: {
                Authorization: 'Bearer ' + this.props.access_token
            }
        }).then((res) => {
            this.setState({
                companies: res.data
            })
        })
    }

    render() {
        return (
            <div className="admin_homepage page_top_margin">
                <div className="container">
                    <h1 className="page_title">COMPANIES</h1>
                    <div className="companies">
                        {this.state.companies !== null ?
                            this.state.companies.results.map((item) =>
                                <div key={item.pk} className="company">
                                    <h3 className="company_name">{item.name}</h3>
                                    <div className="projects">
                                        {item.projects.length > 0 ?
                                            item.projects.map((item) =>
                                                <Link key={item.pk} to={`project/${item.pk}/0/0/0`}>
                                                    <div className="project">
                                                        <span className="project_title">{item.title}</span>
                                                    </div>
                                                </Link>
                                            )
                                            : ''
                                        }
                                    </div>
                                </div>
                            )
                            : ''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        access_token: state.authReducer.access_token
    }
}

export default connect(mapStateToProps,{authCheckState})(AdminHome)
