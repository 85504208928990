import React, { Component } from 'react'
import axios from "axios";
import { connect } from "react-redux";
import { authLogout, setNotification, authCheckState, addNotification } from '../redux/actions/auth'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { compose } from "redux";
import { withRouter } from "react-router";
import NotificationWebSocketInstance from '../user_websocket'

export class Nav extends Component {
    constructor(props) {
        super(props)
        this.notificationsRef = React.createRef();
    }

    componentDidMount() {
        this.waitForActiveUserInfo(() => {
            NotificationWebSocketInstance.disconnect()
            NotificationWebSocketInstance.connect('user_' + this.props.active_user_pk)
            this.waitForSocketConnection(() => {
                NotificationWebSocketInstance.addCallbacks(
                    this.new_notification.bind(this));
            })
        })
    }

    new_notification(data) {
        let new_notification = true
        let updated_notification = {
            ...this.props.active_notifications,
            'notifications': this.props.active_notifications['notifications'].map(item => {
                if (data['notification_type'] === 'project') {
                    if (item['project_pk'] === data.project_pk  && item['notification_type'] === 'project') {
                        new_notification = false
                        return { ...item, 'num_notifications': item.num_notifications + 1 }
                    } else {
                        return item
                    }
                }
                else if (data['notification_type'] === 'discussion' ) {
                    if (item['discussion_pk'] === data.discussion_pk && item['notification_type'] === 'discussion') {
                        new_notification = false
                        return { ...item, 'num_notifications': item.num_notifications + 1 }
                    } else {
                        return item
                    }
                }
                else {
                    return ''
                }
            }
            ),
            total_num_notifications: this.props.active_notifications.total_num_notifications + 1
        }
        if (this.props.active_notifications.total_num_notifications === 0 || new_notification) {
            updated_notification.notifications.push(data)
            new_notification = false
        }
        this.props.addNotification(updated_notification)
    }

    componentWillUnmount() {
        NotificationWebSocketInstance.disconnect()
    }

    waitForActiveUserInfo(callback) {
        const component = this;
        setTimeout(function () {
            if (component.props.active_user_pk !== null && component.props.active_user_pk !== undefined) {
                callback();
                return;
            } else {
                component.waitForActiveUserInfo(callback);
            }
        }, 100);
    }

    waitForSocketConnection(callback) {
        const component = this;
        setTimeout(function () {
            if (NotificationWebSocketInstance.state() === 1) {
                callback();
                return;
            } else {
                component.waitForSocketConnection(callback);
            }
        }, 100);
    }

    notificationToggle = (e) => {
        this.notificationsRef.current.classList.toggle('active')
    }

    notificationOnClick = (notification_type, project_pk, discussion_pk, version_pk, video_time) => {
        const { p_id } = this.props.match.params;

        if (notification_type === 'project') {
            if (parseInt(p_id) !== parseInt(project_pk)) {
                this.props.history.push(`/project/${project_pk}/0/0/0/`)
                window.location.reload();
            } else {
                this.props.history.push(`/project/${project_pk}/${this.props.match.params.v_id}/0/0/`)
                window.location.reload();
                this.notificationsRef.current.classList.toggle('active')
            }
        } else if (notification_type === 'discussion') {
            this.props.history.push(`/project/${project_pk}/${version_pk}/${discussion_pk}/${video_time}`)
            window.location.reload();
        }
    }

    removeNotification = (notification_type, project_pk, discussion_pk) => {
        let body = {
            action: 'update_notification',
            notification_type,
            project_pk,
            discussion_pk,
            user_pk: this.props.active_user_pk
        }
        axios
            .post(`/api/notification`, body, {
                headers: {
                    Authorization: "Bearer " + this.props.access_token,
                },
            })
            .then((res) => {
                this.props.setNotification(res.data['notifications'])
            });

    }
    render() {
        return (
            <div className="nav_component">
                <div className="container">
                    <div className="nav">
                        <div className="logo">SOPA</div>
                        <div className="links">
                            <NavLink exact to="/" className="nav_link" activeClassName="selected">{this.props.admin ? 'CUSTOMERS' : 'PROJECTS'}</ NavLink>
                            <NavLink to="/instructions" className="nav_link" activeClassName="selected">INSTRUCTIONS</ NavLink>
                            <button className="nav_link" onClick={() => this.props.authLogout()}>LOGOUT</button>
                            <button className="nav_link notificationBell" onClick={this.notificationToggle}><FontAwesomeIcon icon={faBell} />
                                <span className="notification_value">{this.props.active_notifications ? this.props.active_notifications.total_num_notifications : ''}</span>
                            </button>
                            <div
                                ref={this.notificationsRef}
                                className={this.props.active_notifications ?
                                    this.props.active_notifications.length > 0 ?
                                        "notifications" : "notifications empty" : ""}>
                                {this.props.active_notifications ?
                                    this.props.active_notifications.notifications.map(item => (
                                        <div className="notification" >
                                            <span className="title"
                                                onClick={() => this.notificationOnClick(item.notification_type, item.project_pk, item.discussion_pk, item.version_pk, item.video_time)}>
                                                {item.title} <span className="amount">{item.num_notifications}</span></span>
                                            <span
                                                className="remove"
                                                onClick={() => this.props.authCheckState(this.removeNotification(item.notification_type, item.project_pk, item.discussion_pk))}>
                                                REMOVE
                                            </span>
                                        </div>)) : ''}
                            </div>
                            {this.props.admin ?
                                <button className="nav_link admin profile_image">
                                    <img src="https://images.unsplash.com/photo-1580135657385-3a968cda83db?ixlib=rb-1.2.1&auto=format&fit=crop&w=655&q=80" alt="" />
                                </button>
                                :
                                <button className="nav_link profile_image">
                                    <img src="https://images.unsplash.com/photo-1575995872537-3793d29d972c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=665&q=80" alt="" />
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        admin: state.authReducer.admin,
        username: state.authReducer.username,
        access_token: state.authReducer.access_token,
        active_user_pk: state.authReducer.user_pk,
        active_notifications: state.authReducer.active_notifications
    }
}


export default compose(withRouter, connect(mapStateToProps, { authLogout, setNotification, authCheckState, addNotification }))(Nav)
